import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

var sliderHome = document.getElementById("home-slider");
if(sliderHome) {
    new Swiper(sliderHome, {
        loop: true,
        modules: [Navigation, Pagination],
        grabCursor: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
    });
}

var sliderSingleProduct = document.getElementById("product-slider");
if(sliderSingleProduct) {
    var thumbs = new Swiper('#product-slider-thumbnails', {
        direction: "horizontal",
        slidesPerView: 3,
        spaceBetween: 6,
        breakpoints: {
            "768": {
                direction: "vertical",
                slidesPerView: 3,
            },
            "992": {
                direction: "horizontal",
                slidesPerView: 3,
            },
            "1400": {
                slidesPerView: 4,
                spaceBetween: 12
            }
        },
    });
    new Swiper(sliderSingleProduct, {
        spaceBetween: 0,
        modules: [Thumbs],
        thumbs: {
            swiper: thumbs,
        }
    });
}