import '../scss/app.scss';

/* ----------------------- */
/* ----- FontAwesome ----- */
/* ----------------------- */

import { library, dom } from 'laravel-core/@fortawesome/fontawesome-svg-core';
import * as icons from './icons';
library.add(...Object.values(icons));
dom.watch();

window.onload = () => {
	//
	// js pour désactiver l'action des liens avec juste une ancre
	//
    document.querySelectorAll('a[href="#"]').forEach((el) => {
        el.addEventListener("click", (event) => {
            event.preventDefault();
        })
    })

	//
	// js pour la réécriture des emails
	//
    document.querySelectorAll('.obfuscated').forEach((el) => {
        alert(el.innerHTML);
        el.innerHTML = el.innerHTML.replace(new RegExp("{AT}", 'g'), '@').replace(new RegExp("{DOT}", 'g'), '.');
		el.classList.remove("opacity-0");
    })

    //
	// js pour afficher/masquer le bouton pour retourner en haut de page
	//
	if(document.querySelector('.scrollto')) {
		var prevScrollpos = window.pageYOffset;

		window.onscroll = function() {
			if(window.outerHeight >= 356) {
				var currentScrollPos = window.pageYOffset;

				if(prevScrollpos <= currentScrollPos || currentScrollPos <= 0) {
                    document.querySelector('body:not(.show-nav) .scrollto').style.bottom = '';
				} else {
                    if (window.outerHeight >= 448) {
                        document.querySelector('body:not(.show-nav) .scrollto').style.bottom = '24px';
                    } else {
                        document.querySelector('body:not(.show-nav) .scrollto').style.bottom = '62px';
					}
				}

				prevScrollpos = currentScrollPos;
			}
		}
	}


	//
	// js pour placer le bouton pour retourner en haut de page
	//
    function displayRetour() {
        let scrollTo = document.querySelector('.scrollto');
        if (scrollTo) {
            let marginLeft = document.querySelector('body > div').clientWidth - scrollTo.clientWidth.toFixed(0)

            scrollTo.style.marginLeft = marginLeft + 'px';
            scrollTo.classList.remove("opacity-0");
		}
	}

	displayRetour();

    addEventListener("resize", () => {
        displayRetour();
    });
}