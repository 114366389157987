tarteaucitron.init({
	"useExternalCss": true,
	"hashtag": "#cookies",
	"highPrivacy": true,
	"orientation": "middle",
	"adblocker": false,
	"showAlertSmall": false,
	"showIcon": false,
	"cookieslist": true,
	"removeCredit": true,
});
tarteaucitron.user.gtagUa = import.meta.env.VITE_GTAG;
tarteaucitron.user.analyticsAnonymizeIp = true;
(tarteaucitron.job = tarteaucitron.job || []).push('gtag'); 