window.addEventListener("load", (event) => {
    //
    // js pour la réécriture des emails
    //
    function deObfuscateEmail(mail) {
        mail.href = mail.href.replace(new RegExp("%7BAT%7D", 'g'), '@').replace(new RegExp("%7BDOT%7D", 'g'), '.');

        let mailSpan = mail.querySelector("span");
        mailSpan.innerText = mailSpan.innerText.replace(new RegExp("{AT}", 'g'), '@').replace(new RegExp("{DOT}", 'g'), '.');

        mail.classList.remove('opacity-0');
    }

    document.querySelectorAll('.obfuscated').forEach((mail) => {
        deObfuscateEmail(mail);
    });

//
// js pour ajouter la class img-fluid aux images présentes dans le wysiwyg
//
// $('.wysiwyg img').addClass('img-fluid').parent('.wp-caption').removeAttr('style');
// document.querySelector('.wysiwyg img').classList.add('img-fluid').parent('.wp-caption').removeAttr('style');
});