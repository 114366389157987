const body = document.querySelector('body');

document.querySelector('.hamburger').addEventListener('click', function() {
	if(document.querySelector('body').classList.contains('show-nav')) {
		body.classList.remove('show-nav');
		body.classList.add('hide-nav');
		setTimeout(function() {
			body.classList.remove('hide-nav');
		}, 500);
	} else {
		body.classList.remove('hide-nav');
		body.classList.add('show-nav');
	}
	return false;
});