import { Modal } from 'laravel-core/bootstrap';

document.querySelectorAll('.add-to-cart').forEach(form => {
    form.addEventListener('submit', (event) => {
        event.preventDefault();
        axios({
            method: 'post',
            url: form.action,
			data: new FormData(form),
        }).then(function (response) {
            if (response.data.status == 200) {
                let data = response.data.data;
                let counter = response.data.counter_in_cart;
                let prevModal;

                if (prevModal = document.querySelector('#cart-modal')) {
                    prevModal.remove();
                }

                document.querySelectorAll('.cart-counter').forEach(cartCounter => {
                    let prevCounter = document.querySelector('.cart-counter div')
                    if(prevCounter) prevCounter.remove();

                    let badge = document.createElement("div");
                    badge.append(counter);
                    badge.classList = 'position-absolute top-100 start-0 translate-bottom translate-middle badge rounded-pill bg-danger';
                    cartCounter.append(badge);
                })

                document.querySelector('body').insertAdjacentHTML( 'beforeend', data );
                const cartModal = new Modal('#cart-modal');
                cartModal.show();
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    });
})

document.querySelectorAll('.cart-quantity-input').forEach(input => {
    input.addEventListener('change', (event) => {
        event.preventDefault();
        let articleId = input.dataset.article;
        let form = document.querySelector('.cart-quantity[data-article="' + articleId + '"]');

        form.submit();
    });
})

document.querySelectorAll('.cart-quantity .btn').forEach(btn => {
    btn.addEventListener('click', (event) => {
        event.preventDefault();
        let articleId = btn.dataset.article;

        let form = document.querySelector('.cart-quantity[data-article="' + articleId + '"]');
        let input = form.querySelector('.cart-quantity-input');
        if (input) {
            let value = input.value;
            let newValue;

            if (btn.classList.contains('btn-increment')) {
                newValue = parseInt(value) + 1;
            } else if (btn.classList.contains('btn-decrement')) {
                newValue = parseInt(value) - 1
            }

            if (newValue > 0) {
                input.value = newValue;
                form.submit();
            }
        }
    });
})